import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useDebounceValue } from 'usehooks-ts';
import { useFormik, FormikValues } from 'formik';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import {
  Utils,
  Messages,
  useAlert,
  useNavigate,
  ActionQueueType,
} from '@gv/triage-components';

import { api } from 'api';
import { cvtRoles } from 'config';
import { setLoading } from 'store/slices/loader';
import { updateForm } from 'store/api/action-queue';
import { updateChatHistory } from 'api/subscription';
import { getTokens, saveCaseForm } from 'utils/storage';
import { useAblyChannel, useDynamicFormProps } from 'hooks';
import { getDynamicFormInitialValues } from 'utils/initials';
import { useAblyChatChannel } from 'hooks/useAblyChatChannel';
import { selectChatMessages } from 'store/slices/chat-messages';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { setIsShowFeedback } from 'store/slices/analytics-export';
import { selectAuthUser, selectHasRoles } from 'store/slices/auth';
import { setOnVideoCall, setVideoCallEnded } from 'store/slices/call';
import { updateCase, createUpdateSubmit } from 'store/api/cases/thunks';
import { changeStep, selectCaseFormStep } from 'store/slices/case-form-step';
import {
  pickUpStreamRoom,
  validateFormCallOutcome,
  getCaseFormValidationSchema,
} from 'utils/config';
import {
  getQueueItemId,
  getDynamicCaseData,
  getActionCenterCaseForm,
  getCaseFormStepWithError,
} from 'utils/helpers';
import {
  selectCurrentTask,
  selectCurrentTaskId,
  selectCurrentTaskForm,
  selectIsTaskSwitching,
} from 'store/api/action-queue/selectors';

import { useCommEntities } from '../hooks';

import { UseFormProps, UseFormReturnProps } from './types';

export const useForm = ({
  endTask,
  isCompletely,
  isCallTransferred,
  setCallTransferred,
}: UseFormProps): UseFormReturnProps => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);
  const task = useAppSelector(selectCurrentTask);
  const caseForm = useAppSelector(selectCurrentTaskForm);
  const currentTaskId = useAppSelector(selectCurrentTaskId);
  const isTaskSwitching = useAppSelector(selectIsTaskSwitching);
  const isCVT = useAppSelector(selectHasRoles(cvtRoles));
  const { client, hospital, hospitalId } = useCommEntities();
  const { activeVideoCall, isVideoCallEnded } = useSelector(
    (state: RootState) => state.call
  );
  const { messages } = useAppSelector(selectChatMessages(task?.channelName));

  const channel = useAblyChannel(
    process.env.REACT_APP_ABLY_CHANNEL ?? 'GuardianVets_Dev_Server_delta'
  );

  const ablyChatChannel = useAblyChatChannel(task?.channelName);

  console.log('ably channel updated', channel);

  const taskId = task ? getQueueItemId(task) : undefined;
  const isDynamicFlow = !!task?.h_is_dynamic_flow_enabled;

  const [formTaskId, setFormTaskId] = useState(taskId);
  const { token } = getTokens();
  const [chatSubmitted, setChatSubmitted] = useState(false);

  const [isShowValidationEmail, setIsShowValidationEmail] = useState(false);

  const taskRef = useRef(task);
  const navigate = useNavigate();

  useEffect(() => {
    taskRef.current = task;
  }, [task]);

  const { handleError, showSuccessAlert } = useAlert();

  const { validationSchema, ...dynamicFormProps } = useDynamicFormProps({
    hospitalId,
  });
  const { resetSteps, passedSteps, passedStepsFieldValues } = dynamicFormProps;

  const showFeedback = async () => {
    const response: any =
      await api.customerFeedback.checkTypeFormHospital(hospitalId);
    dispatch(setIsShowFeedback(response.data?.data?.status));
    const isEditFeedbackEmail = location.pathname.includes('consultation/edit');
    setIsShowValidationEmail(
      response.data?.data?.status && !isEditFeedbackEmail
    );
  };

  useEffect(() => {
    showFeedback();
  }, []);

  const formValidationSchema = useMemo(
    () =>
      isDynamicFlow
        ? validationSchema
        : getCaseFormValidationSchema({
            isCVT,
            hospital,
            isShowValidationEmail,
          }),
    [isCVT, hospital, isDynamicFlow, validationSchema, isShowValidationEmail]
  );

  const initialFormikValues = useMemo(
    () =>
      isDynamicFlow ? getDynamicFormInitialValues(caseForm, client) : caseForm,
    [isDynamicFlow, client, caseForm]
  );

  useEffect(() => {
    if (
      initialFormikValues.id &&
      task?.channelName &&
      authUser?.id &&
      task?.type === ActionQueueType.Chat
    ) {
      (async () => {
        const filteredMessages = messages.filter(
          (message) => message.author_id != authUser?.id && !!message.author_id
        );
        const details = filteredMessages
          .map((message) => message.msg_text)
          .join(' ');
        console.log('update chat history api call details', details);
        if (filteredMessages.length <= 10) {
          if (!chatSubmitted) {
            await updateChatHistory(task?.channelName ?? '', {
              details,
              end_by: authUser?.id,
              hospital_id: task?.h_id ?? 0,
              case_id: initialFormikValues.id,
              status: Number((task as any)?.chatStatus ?? 1),
            });
          }
        }
      })();
    }
  }, [initialFormikValues, task, authUser, messages.length, chatSubmitted]);

  const onSubmit = async (values: FormikValues): Promise<void> => {
    setChatSubmitted(true);
    const startTime = task && task?.start_time;
    const endTime = task && task?.disconnected_at;
    const currentTime = Date.now();
    // Calculate the difference in seconds
    const timeDifferenceInSeconds =
      startTime && (currentTime - startTime) / 1000;
    const timeDifferenceInSecondsWithEnd =
      startTime && endTime && (endTime - startTime) / 1000;

    const payload = isDynamicFlow
      ? getDynamicCaseData({
          values,
          caseForm,
          passedSteps,
          passedStepsFieldValues,
        })
      : values;
    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    try {
      if (task?.type === ActionQueueType.Video) {
        pickUpStreamRoom({ startVideo: false, userId: task.u_id });
        await dispatch(
          createUpdateSubmit({
            task: task!,
            silent: true,
            isDynamicFlow,
            values: payload,
            isSubmitted: true,
          })
        ).unwrap();
        dispatch(setLoading(true));
        dispatch(setVideoCallEnded(false));
        if (!isVideoCallEnded) {
          activeVideoCall?.endCall();
          dispatch(setVideoCallEnded(true));
        }
        dispatch(setOnVideoCall(true));
        dispatch(setLoading(false));
        await endTask({ end: true, completely: true });
        await sleep(30000);
        const getCall = await activeVideoCall?.get();
        const recordingData = await activeVideoCall?.queryRecordings(
          getCall?.call?.session?.id
        );
        await api.videoCall.endVideoCall(
          authUser?.hospital_id,
          {
            status: 3,
            case_id: values.id,
            end_by: authUser?.id,
            room_id: task.channelName,
            file: recordingData?.recordings[0]?.url,
          },
          token,
          dispatch
        );
      } else {
        await dispatch(
          createUpdateSubmit({
            task: task!,
            isDynamicFlow,
            values: payload,
            isSubmitted: true,
          })
        ).unwrap();
      }
      if (isCompletely?.current) {
        if (task?.type === ActionQueueType.Chat) {
          await endTask({ end: true, completely: true, caseId: values.id });
          ablyChatChannel?.publish('end-chat', {
            status: '3',
            type: 'end-chat',
            case_id: values.id,
            end_by: authUser?.id,
            name: authUser?.name,
            hospital_id: hospitalId,
            room_id: task?.channelName,
          });
          dispatch(setOnVideoCall(true));
        } else {
          await endTask({ end: true, completely: true });
        }
      } else if (isCallTransferred) {
        await endTask({ completely: true });
        setCallTransferred(false);
      }
      if (isShowValidationEmail) {
        if (
          timeDifferenceInSecondsWithEnd &&
          timeDifferenceInSecondsWithEnd >= 30
        ) {
          await api.customerFeedback.sendFeedbackForm(
            values.id,
            values?.survey?.feedback_email,
            values?.callOutcome?.type
          );
        } else if (timeDifferenceInSeconds && timeDifferenceInSeconds >= 30) {
          await api.customerFeedback.sendFeedbackForm(
            values.id,
            values?.survey?.feedback_email,
            values?.callOutcome?.type
          );
        }
      }
      navigate('/action-center/queue');
      showSuccessAlert(Messages.CASE_SUBMITTED);
      dispatch(setVideoCallEnded(false));
      if (
        hospital?.type === 2 &&
        (task?.type === ActionQueueType.Video ||
          task?.type === ActionQueueType.Voice)
      ) {
        try {
          await api.subcription.sendFeedbackEmailVoice(hospitalId, values.id);
        } catch (err) {
          console.log('Error in sending feedback email', err);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const formShouldReset = formTaskId !== taskId;

  const form = useFormik({
    onSubmit,
    validateOnMount: true,
    enableReinitialize: formShouldReset,
    validationSchema: formValidationSchema,
    initialValues: initialFormikValues ?? {},
    validate: (values) =>
      validateFormCallOutcome(values, hospital?.timezone?.time_zone_code),
    onReset: () => {
      if (formShouldReset) {
        console.log(`update form task from ${formTaskId} to ${taskId}`);
        setFormTaskId(taskId);
        resetSteps();
      } else {
        console.log(`form reset w/o formShouldReset ${formTaskId} | ${taskId}`);
      }
    },
  });

  useEffect(() => {
    if (formShouldReset) {
      return;
    }

    console.log(
      'reset form like enableReinitialize with changing values only',
      caseForm ?? {}
    );

    form.resetForm({
      errors: form.errors,
      status: form.status,
      touched: form.touched,
      submitCount: form.submitCount,
      isSubmitting: form.isSubmitting,
      isValidating: form.isValidating,
      values: initialFormikValues ?? {},
    });
  }, [formShouldReset, JSON.stringify(caseForm)]);

  const { currentStep, maxOpenedStep } = useAppSelector(
    selectCaseFormStep(currentTaskId)
  );

  const { values, submitForm, setFieldValue } = form;

  const valuesRef = useRef(values);

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const allowToSaveForm = form.isValid && !form.isSubmitting;

  const updateCaseFormIfValid = useCallback(async () => {
    if (!allowToSaveForm) {
      return;
    }
    const formValues = valuesRef.current;
    const currentTask = taskRef.current;
    if (!currentTask || !formValues.id || !formValues.client_id) {
      return;
    }
    const prefix = `[case-auto-save](caseId:${formValues.id}`;
    try {
      await dispatch(
        updateCase({
          silent: true,
          hideLoading: true,
          task: currentTask,
          values: {
            ...formValues,
            brief_description: formValues.problem_summary,
          },
        })
      ).unwrap();
    } catch (error) {
      console.error(`${prefix} failed`, error);
    }
  }, [allowToSaveForm]);

  useEffect(() => {
    if (formShouldReset) {
      console.log(`form should reset, old ${formTaskId}, new ${taskId}`);
    }
  }, [formShouldReset]);

  useEffect(() => {
    resetSteps();
  }, [formShouldReset, resetSteps]);

  const submit = useCallback(async () => {
    const result = await submitForm();
    const { errors } = form;
    const errorStep = getCaseFormStepWithError(errors);
    console.log('[submitCase]: submit case form with values:', {
      form,
      errors,
      errorStep,
      isDynamicFlow,
    });

    if (form?.errors && Object.keys(form.errors).length > 0) {
      Sentry.captureMessage('Submission Error', {
        level: 'warning',
        extra: { form, errors: form.errors },
      });
    }
    if (errorStep && currentTaskId && !isDynamicFlow) {
      console.log('[changeStep]: change step in submit', {
        step: errorStep,
        formId: currentTaskId,
      });
      dispatch(changeStep({ step: errorStep, formId: currentTaskId }));
    }

    return result;
  }, [submitForm, form.errors, isDynamicFlow, currentTaskId]);

  const valuesForUpdateForm =
    JSON.stringify(values) +
    taskId +
    authUser?.id +
    currentStep +
    maxOpenedStep;

  const [valuesRefToSaveLocally] = useDebounceValue(valuesForUpdateForm, 500);
  const [valuesRefToSaveOnServer] = useDebounceValue(valuesForUpdateForm, 3000);

  const formCaseId = values.id;
  const taskCaseId = task?.case_id;

  useEffect(() => {
    console.log(
      'task case form',
      JSON.stringify({ taskId, formCaseId, taskCaseId })
    );
  }, [taskId, formCaseId, taskCaseId]);

  const onSaveCaseForm = (updatedValues?: FormikValues) => {
    if (authUser?.id && taskId && task && !isTaskSwitching) {
      const initialCaseForm = getActionCenterCaseForm(task, authUser);
      const diff = Utils.Object.shallowDifference(
        initialCaseForm,
        updatedValues || valuesRef.current
      );

      if (!!updatedValues) {
        if (!updatedValues.problem_summary?.length) {
          diff.problem_summary = null;
        }
        if (
          !updatedValues.chief_complaints.length &&
          !initialCaseForm.chief_complaints.length
        ) {
          diff.chief_complaints = initialCaseForm.chief_complaints;
        }
      }

      saveCaseForm({
        roomId: taskId,
        userId: authUser.id,
        data: {
          ...diff,
          current_step: currentStep,
          max_opened_step: maxOpenedStep,
        },
      });
    }
  };

  useEffect(() => {
    onSaveCaseForm();
  }, [valuesRefToSaveLocally]); // DON'T SET ANY OTHER DEPS - IT'S BREAK WHEN TASK SWITCHED, JUST ADD TO valuesJson

  useEffect(() => {
    updateCaseFormIfValid();
  }, [updateCaseFormIfValid, valuesRefToSaveOnServer]); // DON'T SET ANY OTHER DEPS - IT'S BREAK WHEN TASK SWITCHED, JUST ADD TO valuesJson

  useEffect(() => {
    if (!isDynamicFlow) {
      return;
    }
    const replaceData = getDynamicFormInitialValues(values, client);
    ['client', 'clientPets', 'pet'].forEach((key) =>
      setFieldValue(key, replaceData[key])
    );
  }, [isDynamicFlow, client, taskId]);

  useEffect(() => {
    return () => {
      if (taskRef.current) {
        dispatch(
          updateForm({ task: taskRef.current, form: valuesRef.current })
        );

        updateCaseFormIfValid();
      }
    };
  }, [updateCaseFormIfValid]);

  return { ...form, onSaveCaseForm, dynamicFormProps, submitForm: submit };
};
